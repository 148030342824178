<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "UpdateButton"
})
</script>

<template>
  <button type="button" class="mt-3 text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800">Load more</button>
</template>

<style scoped>

</style>