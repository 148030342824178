<script setup>

</script>

<template>
  <div class="mx-auto px-5 py-2 mt-10 lg:px-32 lg:pt-12">
    <p class="text-2xl text-opacity-50 text-gray-500">No results :(</p>
  </div>
</template>

<style scoped>

</style>